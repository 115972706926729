import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

interface LanguageSwitcherProps {
  variant?: string;
}

interface LanguageItemProps {
  readonly dark: boolean;
}

interface LanguageSwitcherWrapperProps {
  readonly dark: boolean;
}

const LanguageSwitcherWrapper = styled.div<LanguageSwitcherWrapperProps>`
  display: flex;
  justify-content: ${(props) => (props.dark ? 'auto' : 'space-between')};
`;

const LanguageItem = styled.div<LanguageItemProps>`
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: ${(props) => (props.dark ? '0.75rem' : '0.875rem')};
  font-weight: ${(props) => (props.dark ? '800' : 'inherit')};
  line-height: 1.71;
  background-color: ${({ theme, dark }) => dark ? theme.lozengeColor : 'transparent'};
  color: ${(props) => (props.dark ? '#627d95' : 'rgb(255, 255, 255, 0.5)')};
  padding: ${(props) => (props.dark ? '0.25rem 0.5rem' : '0 0.5rem;')};
  text-transform: capitalize;
  border-right: 1px solid rgb(255, 255, 255, 0.2);
  cursor: pointer;
  &:first-child {
    border-radius: ${(props) => (props.dark ? '4px 0 0 4px' : '0')};
  }
  &:last-child {
    border-right: none;
    border-radius: ${(props) => (props.dark ? '0 4px 4px 0' : '0')};
  }
  &.current {
    color: ${({ dark, theme }) => (dark ? '#ffffff' : theme.primaryColor)};
    background-color: ${(props) => (props.dark ? '#627d95' : 'inherit')};
  }
`;
const localeList = [
  {
    title: 'english',
    code: 'en',
  },
  {
    title: 'dutch',
    code: 'nl',
  },
];
const LanguageSwitcher = ({ variant }: LanguageSwitcherProps) => {
  const [, i18n] = useTranslation();
  const [locale, setLocale] = useState(
    localStorage.getItem('app_locale') || 'en',
  );
  const handleLocale = (lang: string) => {
    setLocale(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('app_locale', lang);
    axios.defaults.headers.common['Accept-Language'] = lang;
  };
  const handleClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <LanguageSwitcherWrapper
      dark={variant ? variant === 'dark' : false}
      onClick={handleClick}
    >
      {localeList.map((loc, index) => (
        <LanguageItem
          dark={variant ? variant === 'dark' : false}
          key={loc.code}
          className={`${locale === loc.code && 'current'}`}
          onClick={() => handleLocale(loc.code)}
        >
          {loc.title}
        </LanguageItem>
      ))}
    </LanguageSwitcherWrapper>
  );
};

export default LanguageSwitcher;
