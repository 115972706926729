import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CaretDownOutlined } from '@ant-design/icons';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

import Header from '../../components/layout/Header';
import PageWrapper from '../../components/layout/PageWrapper';

import styled from 'styled-components';
import { useAppSelector } from '../../store/hooks';
import { Col, Form, message, Row, Select } from 'antd';
import { ClientFeeTemplate } from '../../types';
import {
  HIREPORT_DEFAULT_COMPENSATION_TYPE,
  HIREPORT_DEFAULT_GUARANTEE_PERIOD,
} from '../../constants/recruiters';
import { RcFile } from 'antd/lib/upload';
import {
  createCompanyFeeTemplate,
  getCompanyFeeTemplates,
} from '../../api/company';
import Button from '../../UI/buttons/Button';
import FormItem from '../../components/form/FormItem';
import Input from '../../UI/inputs/Input';
import SelectInput from '../../UI/inputs/Select';
import { FEE_TYPES } from '../../components/recruiter/AddCompanyRecruiterForm';
import {
  ActionLink,
  ActionWrapper,
  DeleteFileIcon,
  FileName,
  FileUploader,
  StyledUploader,
  UploadAction,
} from '../../components/recruitment_management/RecruiterEditAgreement';
import { JobActionButton } from '../../components/settings/atsSettings/styles';
import {
  PAYMENT_TERM_START_TYPES,
  PAYMENT_TERM_TYPES,
} from './MasterContractTemplate';
import { MASTER_CONTRACTS } from '../../constants/routes';
import { useHistoryWithUrlBase } from '../../hooks/useHirePortContext';

const FeeTemplateTableRow = styled.div`
  display: flex;
  flexdirection: row;
  alignitems: center;
  justifycontent: space-between;
  borderbottom: 1px solid #dae5ec;
  padding: 10px 0px;
  padding-left: 0.5rem;
  border-radius: ${({ theme }) => theme.radius};
`;

const FeeTemplateTable = styled.div`
  background: #ffffff;
  border-radius: ${({ theme }) => theme.radius};
  @media (max-width: 576px) {
    padding: 0 1rem;
  }
`;

const FormWrapper = styled.div`
  padding: 2rem;
  background: #ffffff;
  border-radius: ${({ theme }) => theme.radius};
  @media (max-width: 576px) {
    padding: 0 1rem;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 2.5rem;
  width: 100%;
`;

const FormSubtitle = styled.h2`
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #061c2e;
  margin: 0.5rem 0;
`;

const MasterContracts = () => {
  const { t } = useTranslation();
  const history = useHistoryWithUrlBase();
  const [form] = Form.useForm();
  const { user } = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [companyFeeTemplates, setCompanyFeeTemplates] = useState<
    ClientFeeTemplate[]
  >([]);
  const [showSetupForm, setShowSetupForm] = useState(false);
  const [file, setFile] = useState('');
  const [newFileUploading, setNewFileUploading] = useState(false);

  const initialFormValues = {
    recruiter_compensation_type: HIREPORT_DEFAULT_COMPENSATION_TYPE,
    guarantee_period: HIREPORT_DEFAULT_GUARANTEE_PERIOD,
  };

  const handleUploadFile = (file: RcFile): boolean => {
    setFile(file.name);
    setNewFileUploading(true);
    return true;
  };

  const handleFileDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setFile('');
    form.setFieldsValue({ contract: null });
  };

  const onFinish = (values: any) => {
    setLoading(true);
    const data = new FormData();
    if (!values.compensation_type || !values.compensation_type_tooltip) {
      setLoading(false);
      return message.error(t('FEE_SETUP_ERROR'), 5);
    }

    data.append('name', values.name);
    data.append('compensation_type', values.compensation_type);
    data.append('compensation_type_tooltip', values.compensation_type_tooltip);
    data.append('guarantee_period', String(values.guarantee_period));
    data.append('restitution', String(values.restitution));
    data.append('replacement_time', String(values.replacement_time));
    data.append(
      'custom_restitution_agreement',
      values.custom_restitution_agreement,
    );
    data.append('payment_terms', String(values.payment_terms));
    data.append(
      'restitution_payment_terms',
      String(values.restitution_payment_terms),
    );
    data.append('other_information', String(values.other_information));
    data.append('payment_terms_start', String(values.payment_terms_start));
    data.append(
      'exclusivity_for_replacement',
      values.exclusivity_for_replacement,
    );
    if (values.contract?.file.originFileObj) {
      data.append('contract', values.contract.file.originFileObj);
    }
    createCompanyFeeTemplate(data)
      .then((res) => {
        setCompanyFeeTemplates([res.data, ...companyFeeTemplates]);
        setLoading(false);
        setShowSetupForm(!showSetupForm);
        form.setFieldsValue(initialFormValues);
        message.success(t('FEE_SETTINGS_CREATED'), 5);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (!user?.company?.company_wide_fee_settings_enabled) {
      history.push('/profile');
    }
    getCompanyFeeTemplates()
      .then((res) => {
        setCompanyFeeTemplates(res.data);
      })
      .catch(() => { });
    // eslint-disable-next-line
  }, []);

  return (
    <PageWrapper>
      <Header title={t('MENU_MASTER_CONTRACTS_TOOLTIP')}>
        <span></span>
      </Header>
      <FormWrapper>
        <Button type="primary" onClick={() => setShowSetupForm(!showSetupForm)}>
          {t('CREATE_FEE_SETUP')}
        </Button>
        {showSetupForm && (
          <StyledForm
            scrollToFirstError={true}
            layout="vertical"
            name="invitation"
            onFinish={onFinish}
            form={form}
            initialValues={initialFormValues}
          >
            <div style={{ width: '100%', borderBottom: '1px solid #DAE5EC' }}>
              <FormSubtitle style={{ fontSize: '18px', paddingBottom: '1rem' }}>
                {t('NEW_FEE_SETUP')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={10}>
                <FormItem name="name" className="no-margin">
                  <Input
                    placeholder={t('MASTER_CONTRACT_NAME')}
                    maxLength={20}
                    size="large"
                    type="text"
                  />
                </FormItem>
              </Col>
            </Row>
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle style={{ fontSize: '16px', paddingBottom: '1rem' }}>
                {t('FEE_SETUP_FEE_INFORMATION')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label="FEE STRUCTURE"
                  name="compensation_type"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {FEE_TYPES.map((fee, index) => (
                      <Select.Option value={fee.fee_value} key={index}>
                        {fee.fee_name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="FEE DETAILS"
                  name="compensation_type_tooltip"
                  className="no-margin"
                >
                  <Input
                    type="number"
                    placeholder={t('JOB_FEE_DETAILS_PLACEHOLDER')}
                    maxLength={70}
                    size="large"
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={t('GUARANTEE_PERIOD')}
                  name="guarantee_period"
                  className="no-margin"
                >
                  <Input
                    placeholder={t('GUARANTEE_PERIOD_PLACEHOLDER')}
                    maxLength={70}
                    size="large"
                    type="number"
                  />
                </FormItem>
              </Col>
            </Row>
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle style={{ fontSize: '16px', paddingBottom: '1rem' }}>
                {t('FEE_SETUP_RESTITUTION')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label={t('JOB_RESTITUTION')}
                  name="restitution"
                  className="no-margin"
                >
                  <Input
                    placeholder={t('COMPANY_AGREEMENT_RESTITUTION_PLACEHOLDER')}
                    maxLength={70}
                    size="large"
                    type="number"
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={t('JOB_REPLACEMENT_TIME')}
                  name="replacement_time"
                  className="no-margin"
                >
                  <Input
                    placeholder={t(
                      'COMPANY_AGREEMENT_REPLACEMENT_TIME_PLACEHOLDER',
                    )}
                    maxLength={70}
                    size="large"
                    type="number"
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={t('JOB_EXCLUSIVITY_FOR_REPLACEMENT')}
                  name="exclusivity_for_replacement"
                  className="no-margin"
                >
                  <SelectInput
                    allowClear
                    placeholder={t(
                      'COMPANY_AGREEMENT_EXCLUSIVITY_FOR_REPLACEMENT_PLACEHOLDER',
                    )}
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {['Yes', 'No'].map((val, index) => (
                      <Select.Option value={val} key={index}>
                        {val}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={24}>
                <FormItem
                  label={t('JOB_CUSTOM_RESTITUTION_AGREEMENT')}
                  name="custom_restitution_agreement"
                  className="no-margin"
                >
                  <Input maxLength={70} size="large" type="text" />
                </FormItem>
              </Col>
            </Row>
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle style={{ fontSize: '16px', paddingBottom: '1rem' }}>
                {t('FEE_SETUP_PAYMENT_TERMS')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label={t('JOB_PAYMENT_TERMS')}
                  name="payment_terms"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {PAYMENT_TERM_TYPES.map((payment_term, index) => (
                      <Select.Option value={payment_term.value} key={index}>
                        {payment_term.name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={t('JOB_PAYMENT_TERMS_START')}
                  name="payment_terms_start"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {PAYMENT_TERM_START_TYPES.map((payment_term, index) => (
                      <Select.Option value={payment_term.value} key={index}>
                        {payment_term.name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label={t('JOB_RESTITUTION_PAYMENT_TERMS')}
                  name="restitution_payment_terms"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {PAYMENT_TERM_TYPES.map((payment_term, index) => (
                      <Select.Option value={payment_term.value} key={index}>
                        {payment_term.name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
            </Row>
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle style={{ fontSize: '16px', paddingBottom: '1rem' }}>
                {t('JOB_OTHER_INFORMATION')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={24}>
                <FormItem name="other_information" className="no-margin">
                  <Input maxLength={70} size="large" type="text" />
                </FormItem>
              </Col>
            </Row>
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle style={{ fontSize: '16px', paddingBottom: '1rem' }}>
                {t('RECRUITER_CONTRACT')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <>
                {file && !newFileUploading ? (
                  <div style={{ display: 'flex' }}>
                    <ActionWrapper>
                      <ActionLink
                        href={file as string}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DownloadIcon />
                        {t('COMPANY_AGREEMENT_DOWNLOAD_CONTRACT')}
                      </ActionLink>
                    </ActionWrapper>
                    <UploadAction>
                      <button
                        style={{
                          border: 'none',
                          display: 'flex',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <DeleteFileIcon onClick={handleFileDelete} />
                      </button>
                    </UploadAction>
                  </div>
                ) : (
                  <Col span={8}>
                    <FormItem
                      label={t('COMPANY_AGREEMENT_CONTRACT')}
                      name="contract"
                    >
                      <StyledUploader
                        name="file"
                        showUploadList={false}
                        beforeUpload={handleUploadFile}
                        customRequest={() => void 0}
                      >
                        <FileUploader>
                          <FileName>{file}</FileName>
                          <UploadAction>
                            <Button type={'default'} size="small">
                              {t('CHOOSE_FILE')}
                            </Button>

                            {file && (
                              <DeleteFileIcon onClick={handleFileDelete} />
                            )}
                          </UploadAction>
                        </FileUploader>
                      </StyledUploader>
                    </FormItem>
                  </Col>
                )}
              </>
            </Row>
            <Row
              gutter={16}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '2rem',
              }}
            >
              <FormItem>
                <Button
                  style={{ marginRight: '1rem' }}
                  onClick={() => setShowSetupForm(!showSetupForm)}
                >
                  {t('CANCEL_TEXT')}
                </Button>
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t('CREATE')}
                </Button>
              </FormItem>
            </Row>
          </StyledForm>
        )}
        {!showSetupForm && (
          <FeeTemplateTable>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderBottom: '1px solid #DAE5EC',
                padding: '6px 0',
                paddingLeft: '0.5rem',
                marginTop: '4rem',
                color: '#627D95',
                fontWeight: 600,
              }}
            >
              <div style={{ paddingRight: '2rem' }}>
                {t('MASTER_CONTRACT_NAME')}
              </div>
            </div>

            <div
              style={{
                overflow: 'auto',
              }}
            >
              {companyFeeTemplates.map((feeTemplate) => (
                <FeeTemplateTableRow
                  style={{
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #DAE5EC',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: '1.5rem' }}>
                      {feeTemplate.name}
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      paddingRight: '0.5rem',
                    }}
                  >
                    <JobActionButton
                      size="small"
                      type="primary"
                      onClick={() =>
                        history.push(`${MASTER_CONTRACTS}/${feeTemplate.id}`)
                      }
                    >
                      <div
                        style={{ paddingRight: '1rem', paddingLeft: '1rem' }}
                      >
                        {t('CONFIGURE')}
                      </div>
                    </JobActionButton>
                  </div>
                </FeeTemplateTableRow>
              ))}
            </div>
          </FeeTemplateTable>
        )}
      </FormWrapper>
    </PageWrapper>
  );
};

export default MasterContracts;
