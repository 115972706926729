import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { User } from "../types";

const useIntercomUpdate = (user: User | null) => {
  // I guess this dependency triggers an update if the URL changes
  const location = useLocation();
  useEffect(() => {
    if (!user)
      return;
    const { Intercom: intercom } = window as any;
    if (!intercom)
      return;
    intercom('update', {
      app_id: process.env.REACT_APP_INTERCOM_ID,
      email: user.email,
      name: user.name,
      user_id: user.id,
      user_role: user.role,
      user_has_default_tier: user.role === 'recruiter' ? user.has_default_tier : false,
    });
  }, [location, user]);
};

export default useIntercomUpdate;
