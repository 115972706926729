import React from 'react';
import SingleJob from '../../SingleJob';
import { useHistoryWithUrlBase } from '../../../hooks/useHirePortContext';
import SmallBackButton from '../../../UI/buttons/SmallBackButton';

const ChromiumSingleJobPage = () => {
  const history = useHistoryWithUrlBase();
  return (
    <>
      <SmallBackButton size="middle" onClick={() => { history.push('/assign-recruiters') }} />
      <SingleJob
        isPublic={false}
        showBackButton={false}
      />
    </>
  )
};

export default ChromiumSingleJobPage;
