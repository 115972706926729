import { TFunction } from 'react-i18next';
import { Job } from '../types';
import { addDecimalPoints } from './candidate';

const calculateRecruiterFee = (
  compensationDetails: number,
  type: 'percentage' | 'fixed',
): number => {
  let fee: number;

  if (type === 'percentage') {
    fee = compensationDetails * 0.7;
    fee = parseFloat(fee.toFixed(1));
  } else {
    fee = Math.floor(compensationDetails * 0.7);
  }

  return fee;
};

export const getRecruiterFeeForCandidateCard = (
  t: TFunction<'translation'>,
  compensation_type?: Job['recruiter_compensation_type'],
  recruiter_compensation_type_tooltip?: Job['recruiter_compensation_type_tooltip'],
) => {
  const hasHireportFee = !compensation_type;

  if (!compensation_type || !recruiter_compensation_type_tooltip) {
    return {
      recruiterFee: '-',
      fallbackActive: true,
      hasHireportFee,
    };
  }

  switch (compensation_type) {
    case 'percentage':
      return {
        recruiterFee: `${calculateRecruiterFee(
          recruiter_compensation_type_tooltip,
          'percentage',
        )}% ${t('of yearly salary')}`,
        fallbackActive: false,
        hasHireportFee,
      };
    case 'fixed':
      return {
        recruiterFee: `€ ${calculateRecruiterFee(
          recruiter_compensation_type_tooltip,
          'fixed',
        )}`,
        fallbackActive: false,
        hasHireportFee,
      };
    default:
      return {
        recruiterFee: '-',
        fallbackActive: true,
        hasHireportFee,
      };
  }
};

const getRecruiterFeeSummary = (
  t: TFunction<'translation'>,
  job: Job,
  isClient?: boolean,
) => {
  // use the details in a recruiter/company agreement, if present
  // then the details in the job flex fee, if present.
  const agreement =
    Boolean(job.company_agreement?.company || job.recruiter_company_agreement?.company) ?? false;
  const compensation_type = job.company_agreement
    ? job.company_agreement?.compensation_type
    : job.recruiter_company_agreement?.compensation_type
    ? job.recruiter_company_agreement.compensation_type
    : job.recruiter_compensation_type;
  const compensation_details = job.company_agreement
    ? job.company_agreement?.compensation_type_tooltip
    : job.recruiter_company_agreement?.compensation_type_tooltip
    ? job.recruiter_company_agreement.compensation_type_tooltip
    : job.recruiter_compensation_type_tooltip;

  const hasHireportFee =
    !job.company_agreement?.compensation_type &&
    !job.recruiter_company_agreement?.compensation_type;

  switch (compensation_type) {
    case 'percentage':
      return {
        recruiterFee:
          isClient || agreement
            ? `${compensation_details}% ${t('of yearly salary')}`
            : `${calculateRecruiterFee(
                compensation_details,
                'percentage',
              )}% ${t('of yearly salary')}`,
        fallbackActive: false,
        hasHireportFee,
      };
    case 'fixed':
      return {
        recruiterFee:
          isClient || agreement
            ? `€ ${compensation_details}`
            : `${t('Fixed')}: ${calculateRecruiterFee(
                compensation_details,
                'fixed',
              )}`,
        fallbackActive: false,
        hasHireportFee,
      };
  }

  // this is the fallback in case neither of the compensation types are set - use `earn_amount_2` which is based on salary brackets
  const recruiterFeeAmount = job.earn_amount_2
    ? addDecimalPoints(job.earn_amount_2.toString())
    : null;
  const recruiterFee = recruiterFeeAmount
    ? `${t('Up to')} €${recruiterFeeAmount}`
    : '-';

  return { recruiterFee, fallbackActive: true, hasHireportFee };
};

export default getRecruiterFeeSummary;
