import React, { useState } from 'react';
import { Alert, Form } from 'antd';
import styled from 'styled-components';
import Button from '../../UI/buttons/Button';
import FormItem from '../form/FormItem';
import { loginUser } from '../../store/userSlice';
import { useAppDispatch } from '../../store/hooks';
import { useHistory } from 'react-router-dom';
import { DASHBOARD, JOBS } from '../../constants/routes';
import InputPassword from '../../UI/inputs/InputPassword';
import ResetPasswordPopUp from './ResetPasswordPopUp';
import { useTranslation } from 'react-i18next';
import Input from '../../UI/inputs/Input';

const ForgotText = styled.p`
  color: ${({ theme }) => theme.primaryColor};
  font-size: 0.875rem;
  line-height: 1.71;
  margin: 0.5rem 0;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const ErrorMessage = styled(Alert)`
  background-color: ${(props) =>
    props.type === 'error' ? '#ffeeee' : 'rgba(52,206,114,0.19)'};
  margin-bottom: 1.5rem;
  border-color: transparent;
  border-radius: ${({ theme }) => theme.radius};
  & .ant-alert-message {
    color: #061c2e;
  }
`;
const StyledButton = styled(Button)`
@media (max-width: 768px) {
    margin-top: 1.5rem;
  }
`
const LoginForm = () => {
  const [resetPass, setResetPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const [error, setError] = useState('');

  const onFinish = async (values: any) => {
    setLoading(true)
    const user = await dispatch(loginUser(values));
    if (user.payload?.user) {
      setError('');
      document.referrer.includes('/jobs') ? history.push(JOBS) : history.push(DASHBOARD);
      // @ts-ignore
      localStorage.setItem("intercomID", process.env.REACT_APP_INTERCOM_ID);
      // @ts-ignore
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_ID,
        email: user.payload.user.email,
        name: user.payload.user.name,
        user_id: user.payload.user.id,
        user_role: user.payload.user.role,
        user_has_default_tier: user.payload.role === 'recruiter' ? user.payload.has_default_tier : false,

      });
    }
    if (user.payload?.error) {
      let msg = user.payload?.error;
      if (user?.payload?.data?.message) {
        msg = user?.payload?.data?.message;
      }
      setError(msg);
    }
    await setLoading(false)
  };

  return (
    <>
      <Form layout="vertical" name="login" onFinish={onFinish} form={form} scrollToFirstError={true}>
        {error && <ErrorMessage message={error} type="error" />}
        <FormItem label={t('EMAIL_LABEL')} name="email">
          <Input size="large" />
        </FormItem>
        <FormItem
          className="no-margin"
          label={t('PASSWORD_LABEL')}
          name="password"
        >
          <InputPassword />
        </FormItem>
        <ForgotText onClick={() => setResetPass(true)}>
          {t('FORGOT_PASSWORD_TEXT')}?
        </ForgotText>
        <FormItem className="text-right">
          <StyledButton
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 'auto' }}
            loading={loading}
          >
            {t('LOG_IN_TEXT')}
          </StyledButton>
        </FormItem>
      </Form>
      <ResetPasswordPopUp visible={resetPass} setVisible={setResetPass} />
    </>
  );
};

export default LoginForm;
