import React, { ReactNode, useEffect } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ATS_SETTINGS, JOBS, MASTER_CONTRACTS, MESSAGES, RECRUITER, RECRUITER_MANAGEMENT } from '../../../constants/routes';
import BrowserPluginRoute from '../../../components/route/BrowserPluginRoute';
import ChromiumAssignRecruitersPage from './ChromiumAssignRecruiters';
import ChromiumChat from './ChromiumChat';
import ChromiumAtsSettingsPage from './ChromiumAtsSettings';
import ChromiumSingleJobPage from './ChromiumSingleJob';
import RecruiterManagement from '../../RecruiterManagement';
import AnalyticsPage from '../../Analytics';
import ChromiumJobStepsPage from './ChromiumJobSteps';
import MasterContracts from '../../MasterContracts/MasterContracts';
import MasterContractTemplate from '../../MasterContracts/MasterContractTemplate';
import ChromiumAtsJobSettingsPage from './ChromiumAtsJobSettings';
import RecruiterPublicProfile from '../../RecruiterPublicProfile';
import Messages from '../../Messages';
import { packs } from './constants';

const RedirectComponent = () => {
  const history = useHistory();
  const location = history.location;
  const { to } = Object.fromEntries(new URLSearchParams(location.search));

  useEffect(() => {
    if (!to)
      return;
    history.replace(to.toString());
  }, [history, to]);

  return (
    to && <>Redirecting to <a href={to.toString()}>this page</a>...</>
  ) || null;
}

// !WB: I didn't figure out a way to automatically pass through routing to the next level, but ideally only
// entries with custom behaviour should be declared here. As it stands, all plugin-accessible pages need to be
const ChromiumRoutes = ({ noMatch }: { noMatch: ReactNode }) => {
  const match = useRouteMatch();
  return (
    <Switch>
      {packs.map((pack) => (
        <Route key={pack} path={`${match.path}/${pack}` as string}>
          <Switch>
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}/assign-recruiters`} exact component={ChromiumAssignRecruitersPage} />
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}${RECRUITER_MANAGEMENT}`} exact component={RecruiterManagement} />
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}${MASTER_CONTRACTS}`} exact component={MasterContracts} />
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}${MASTER_CONTRACTS}/:id`} exact component={MasterContractTemplate} />
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}/chat`} exact component={ChromiumChat} />
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}${MESSAGES}/:contactId`} exact component={Messages} />
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}${MESSAGES}/:contactId/:interviewId`} exact component={Messages} />
            {/* "retool" rather pack={pack} than "analytics" here because I found the iframe was being blocked by the browser otherwise :shrug: */}
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}/retool`} exact component={AnalyticsPage} />
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}${ATS_SETTINGS}`} exact component={ChromiumAtsSettingsPage} />
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}${ATS_SETTINGS}${JOBS}/:id`} exact component={ChromiumAtsJobSettingsPage} />
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}${JOBS}/:id`} exact component={ChromiumSingleJobPage} />
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}${JOBS}/edit/:id/:step`} exact component={ChromiumJobStepsPage} />
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}${RECRUITER}/:recruiterId/edit/:recruiterCompanyAgreementId`} exact component={RecruiterPublicProfile} />
            <BrowserPluginRoute pack={pack} path={`${match.path}/${pack}/redirect`} exact component={RedirectComponent} />
          </Switch>
        </Route>
      ))}
      {/* !WB: temporarily keeping these fallback routes defined until we update the plugin */}
      {/* !TODO: DELETE THESE LATER! */}
      <Switch>
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}/assign-recruiters`} exact component={ChromiumAssignRecruitersPage} />
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}${RECRUITER_MANAGEMENT}`} exact component={RecruiterManagement} />
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}${MASTER_CONTRACTS}`} exact component={MasterContracts} />
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}${MASTER_CONTRACTS}/:id`} exact component={MasterContractTemplate} />
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}/chat`} exact component={ChromiumChat} />
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}${MESSAGES}/:contactId`} exact component={Messages} />
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}${MESSAGES}/:contactId/:interviewId`} exact component={Messages} />
        {/* "retool" rather pack={'recruitee'} than "analytics" here because I found the iframe was being blocked by the browser otherwise :shrug: */}
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}/retool`} exact component={AnalyticsPage} />
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}${ATS_SETTINGS}`} exact component={ChromiumAtsSettingsPage} />
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}${ATS_SETTINGS}${JOBS}/:id`} exact component={ChromiumAtsJobSettingsPage} />
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}${JOBS}/:id`} exact component={ChromiumSingleJobPage} />
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}${JOBS}/edit/:id/:step`} exact component={ChromiumJobStepsPage} />
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}${RECRUITER}/:recruiterId/edit/:recruiterCompanyAgreementId`} exact component={RecruiterPublicProfile} />
        <BrowserPluginRoute pack={'recruitee'} path={`${match.path}/redirect`} exact component={RedirectComponent} />
      </Switch>
      {/* /DELETE */}
      {noMatch}

    </Switch>
  )
};

export default ChromiumRoutes;
