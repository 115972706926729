import React, { useEffect, useState } from 'react';
import { Form, Select, Col, Row, message } from 'antd';
import FormTitle from '../../components/auth/FormTitle';
import Button from '../../UI/buttons/Button';
import styled from 'styled-components';
import FormItem from './FormItem';
import { CaretDownOutlined } from '@ant-design/icons';
import SelectInput from '../../UI/inputs/Select';
import Input from '../../UI/inputs/Input';
import { FEE_TYPES } from '../recruiter/AddCompanyRecruiterForm';
import { updateJobFlexibleFee } from '../../api/jobs';
import { ClientFeeTemplate, Job } from '../../types';
import { JOBS } from '../../constants/routes';
import {
  HIREPORT_DEFAULT_COMPENSATION_TYPE,
  HIREPORT_DEFAULT_GUARANTEE_PERIOD,
} from '../../constants/recruiters';
import { useTranslation } from 'react-i18next';
import { getCompanyFeeTemplates } from '../../api/company';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import {
  ActionLink,
  ActionWrapper,
  DeleteFileIcon,
  FileName,
  FileUploader,
  StyledUploader,
  UploadAction,
} from '../recruitment_management/RecruiterEditAgreement';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { RcFile } from 'antd/lib/upload';

import { useAppSelector } from '../../store/hooks';
import {
  PAYMENT_TERM_START_TYPES,
  PAYMENT_TERM_TYPES,
} from '../../pages/MasterContracts/MasterContractTemplate';
import { useHistoryWithUrlBase } from '../../hooks/useHirePortContext';

const FormActions = styled.div`
  padding: 1.5rem 2rem;
  border-radius: ${({ theme }) => theme.radius};
  background-color: ${({ theme }) => theme.lozengeColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormSubtitle = styled.h2`
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #061c2e;
  margin: 0.5rem 0;
`;

type JobPSLFormProps = {
  job: Job;
  company_wide_fee_settings_enabled: boolean;
  onFinish: (id: string) => void;
};

export default function JobFeeSettings({
  job,
  company_wide_fee_settings_enabled,
  onFinish,
}: JobPSLFormProps) {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const history = useHistoryWithUrlBase();
  const { user } = useAppSelector((state) => state.user);
  const extendedRecruiterAgreementModuleEnabled =
    user?.company?.extended_recruiter_agreement_enabled;
  const companyWideFeeSettingsEnabled =
    user?.company?.company_wide_fee_settings_enabled;

  let [loading, setLoading] = useState(false);
  const [companyFeeTemplates, setCompanyFeeTemplates] = useState<
    ClientFeeTemplate[]
  >([]);
  const [showExtendedFeeSettings, setShowExtendedFeeSettings] = useState(true);
  const [file, setFile] = useState(job?.contract ?? '');
  const [newFileUploading, setNewFileUploading] = useState(false);
  const initialFormValues = {
    recruiter_compensation_type:
      job.recruiter_compensation_type ?? HIREPORT_DEFAULT_COMPENSATION_TYPE,
    recruiter_compensation_type_tooltip: job.recruiter_compensation_type_tooltip
      ? Number(job.recruiter_compensation_type_tooltip)
      : null,
    payment_terms:
      PAYMENT_TERM_TYPES.find((x) => x.value === job.payment_terms)?.value ??
      PAYMENT_TERM_TYPES[0].value,
    restitution: job.restitution,
    restitution_payment_terms:
      PAYMENT_TERM_TYPES.find((x) => x.value === job.restitution_payment_terms)
        ?.value ?? PAYMENT_TERM_TYPES[0].value,
    payment_terms_start: job.payment_terms_start,
    custom_restitution_agreement: job.custom_restitution_agreement,
    other_information: job.other_information,
    replacement_time: job.replacement_time,
    guarantee_period: job.guarantee_period ?? HIREPORT_DEFAULT_GUARANTEE_PERIOD,
    exclusivity_for_replacement:
      job.exclusivity_for_replacement === true
        ? 'Yes'
        : job.exclusivity_for_replacement === false
          ? 'No'
          : null,
  };

  const handleClick = (nextPage: boolean) => {
    try {
      setLoading(true);
      const draft = form.getFieldsValue();
      const data = new FormData();

      data.append(
        'recruiter_compensation_type',
        draft.recruiter_compensation_type ?? '',
      );
      data.append(
        'recruiter_compensation_type_tooltip',
        draft.recruiter_compensation_type_tooltip ?? '',
      );
      data.append('payment_terms', draft.payment_terms ?? '');
      data.append('restitution', draft.restitution ?? '');
      data.append(
        'restitution_payment_terms',
        draft.restitution_payment_terms ?? '',
      );
      data.append('replacement_time', draft.replacement_time ?? '');
      data.append('guarantee_period', draft.guarantee_period ?? '');
      data.append('other_information', draft.other_information ?? '');
      data.append('payment_terms_start', draft.payment_terms_start ?? '');
      data.append(
        'custom_restitution_agreement',
        draft.custom_restitution_agreement ?? '',
      );
      data.append(
        'exclusivity_for_replacement',
        draft.exclusivity_for_replacement === 'Yes'
          ? 'true'
          : draft.exclusivity_for_replacement === 'No'
            ? 'false'
            : '',
      );

      if (draft.contract?.file?.originFileObj) {
        data.append('contract', draft.contract.file.originFileObj);
      } else if (file) {
        data.append('contract_url', file as string);
      } else if (!file) {
        data.append('contract', '');
      }

      updateJobFlexibleFee({
        jobId: job.id,
        payload: data,
      })
        .then(() => {
          setLoading(false);
          message.success(t('JOB_UPDATED'));
          if (!nextPage) history.push(`${JOBS}/${job?.id}`);
        })
        .catch(() => {
          setLoading(false);
          message.error(t('UPDATE_ERROR'));
        });

      if (nextPage) {
        onFinish(job.id);
      }
    } catch (error) {
      message.error(t('UPDATE_ERROR'));
    }
  };

  const handleUploadFile = (file: RcFile): boolean => {
    setFile(file.name);
    setNewFileUploading(true);
    return true;
  };

  const handleFileDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setFile('');
    form.setFieldsValue({ contract: null });
  };

  const onFeeTemplateChange = (id: string) => {
    const feeTemplate = companyFeeTemplates.find(
      (template) => template.id === id,
    );

    if (feeTemplate?.id) {
      form.setFieldsValue({
        ...feeTemplate,
        name: form.getFieldValue('name'),
        exclusivity_for_replacement:
          feeTemplate.exclusivity_for_replacement === true
            ? 'Yes'
            : feeTemplate.exclusivity_for_replacement === false
              ? 'No'
              : '',
        recruiter_compensation_type: feeTemplate.compensation_type,
        recruiter_compensation_type_tooltip:
          feeTemplate.compensation_type_tooltip,
      });
      setFile(feeTemplate.contract ?? '');
    }
  };

  useEffect(() => {
    if (company_wide_fee_settings_enabled) {
      getCompanyFeeTemplates().then((res: any) => {
        setCompanyFeeTemplates(res.data ?? []);
        if (!res.data || res.data.length === 0) {
          setShowExtendedFeeSettings(true);
        } else {
          setShowExtendedFeeSettings(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      scrollToFirstError={true}
      layout="vertical"
      name="job-form"
      form={form}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      initialValues={initialFormValues}
    >
      <div>
        <FormTitle text={t('JOB_FEE_SETTINGS')} />
        <p>{t('PSL_FORM_FEE_DESCRIPTION')}</p>
        {!extendedRecruiterAgreementModuleEnabled &&
          !companyWideFeeSettingsEnabled && (
            <>
              <div
                style={{
                  width: '100%',
                  marginTop: '1rem',
                  borderBottom: '1px solid #DAE5EC',
                }}
              >
                <FormSubtitle
                  style={{ fontSize: '16px', paddingBottom: '1rem' }}
                >
                  {t('FEE_SETUP_FEE_INFORMATION')}
                </FormSubtitle>
              </div>
              <Row gutter={16} style={{ marginTop: '1rem' }}>
                <Col span={8}>
                  <FormItem
                    label="FEE STRUCTURE"
                    name="recruiter_compensation_type"
                    className="no-margin"
                  >
                    <SelectInput
                      size="large"
                      suffixIcon={
                        <CaretDownOutlined style={{ color: '#000' }} />
                      }
                    >
                      {FEE_TYPES.map((fee, index) => (
                        <Select.Option value={fee.fee_value} key={index}>
                          {fee.fee_name}
                        </Select.Option>
                      ))}
                    </SelectInput>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="FEE DETAILS"
                    name="recruiter_compensation_type_tooltip"
                    className="no-margin"
                  >
                    <Input
                      type="number"
                      placeholder={t('JOB_FEE_DETAILS_PLACEHOLDER')}
                      maxLength={70}
                      size="large"
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row
                gutter={16}
                style={{ marginTop: '1rem', marginBottom: '2rem' }}
              >
                <Col span={24}>
                  <FormItem
                    label={t('JOB_OTHER_INFORMATION')}
                    name="other_information"
                    className="no-margin"
                  >
                    <Input maxLength={70} size="large" type="text" />
                  </FormItem>
                </Col>
              </Row>
            </>
          )}

        {!companyWideFeeSettingsEnabled &&
          extendedRecruiterAgreementModuleEnabled && (
            <>
              <div
                style={{
                  width: '100%',
                  marginTop: '1rem',
                  borderBottom: '1px solid #DAE5EC',
                }}
              >
                <FormSubtitle
                  style={{ fontSize: '16px', paddingBottom: '1rem' }}
                >
                  {t('FEE_SETUP_FEE_INFORMATION')}
                </FormSubtitle>
              </div>
              <Row gutter={16} style={{ marginTop: '1rem' }}>
                <Col span={8}>
                  <FormItem
                    label="FEE STRUCTURE"
                    name="recruiter_compensation_type"
                    className="no-margin"
                  >
                    <SelectInput
                      size="large"
                      suffixIcon={
                        <CaretDownOutlined style={{ color: '#000' }} />
                      }
                    >
                      {FEE_TYPES.map((fee, index) => (
                        <Select.Option value={fee.fee_value} key={index}>
                          {fee.fee_name}
                        </Select.Option>
                      ))}
                    </SelectInput>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="FEE DETAILS"
                    name="recruiter_compensation_type_tooltip"
                    className="no-margin"
                  >
                    <Input
                      type="number"
                      placeholder={t('JOB_FEE_DETAILS_PLACEHOLDER')}
                      maxLength={70}
                      size="large"
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label={t('GUARANTEE_PERIOD')}
                    name="guarantee_period"
                    className="no-margin"
                  >
                    <Input
                      placeholder={t('GUARANTEE_PERIOD_PLACEHOLDER')}
                      maxLength={70}
                      size="large"
                      type="number"
                    />
                  </FormItem>
                </Col>
              </Row>
              <div
                style={{
                  width: '100%',
                  marginTop: '2.5rem',
                  borderBottom: '1px solid #DAE5EC',
                }}
              >
                <FormSubtitle
                  style={{ fontSize: '16px', paddingBottom: '1rem' }}
                >
                  {t('FEE_SETUP_RESTITUTION')}
                </FormSubtitle>
              </div>
              <Row gutter={16} style={{ marginTop: '1rem' }}>
                <Col span={8}>
                  <FormItem
                    label={t('JOB_RESTITUTION')}
                    name="restitution"
                    className="no-margin"
                  >
                    <Input
                      placeholder={t(
                        'COMPANY_AGREEMENT_RESTITUTION_PLACEHOLDER',
                      )}
                      maxLength={70}
                      size="large"
                      type="number"
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label={t('JOB_REPLACEMENT_TIME')}
                    name="replacement_time"
                    className="no-margin"
                  >
                    <Input
                      placeholder={t(
                        'COMPANY_AGREEMENT_REPLACEMENT_TIME_PLACEHOLDER',
                      )}
                      maxLength={70}
                      size="large"
                      type="number"
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label={t('JOB_EXCLUSIVITY_FOR_REPLACEMENT')}
                    name="exclusivity_for_replacement"
                    className="no-margin"
                  >
                    <SelectInput
                      placeholder={t(
                        'COMPANY_AGREEMENT_EXCLUSIVITY_FOR_REPLACEMENT_PLACEHOLDER',
                      )}
                      size="large"
                      allowClear
                      suffixIcon={
                        <CaretDownOutlined style={{ color: '#000' }} />
                      }
                    >
                      {['Yes', 'No'].map((val, index) => (
                        <Select.Option value={val} key={index}>
                          {val}
                        </Select.Option>
                      ))}
                    </SelectInput>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: '1rem' }}>
                <Col span={24}>
                  <FormItem
                    label={t('JOB_CUSTOM_RESTITUTION_AGREEMENT')}
                    name="custom_restitution_agreement"
                    className="no-margin"
                  >
                    <Input maxLength={70} size="large" type="text" />
                  </FormItem>
                </Col>
              </Row>
              <div
                style={{
                  width: '100%',
                  marginTop: '2.5rem',
                  borderBottom: '1px solid #DAE5EC',
                }}
              >
                <FormSubtitle
                  style={{ fontSize: '16px', paddingBottom: '1rem' }}
                >
                  {t('FEE_SETUP_PAYMENT_TERMS')}
                </FormSubtitle>
              </div>
              <Row gutter={16} style={{ marginTop: '1rem' }}>
                <Col span={8}>
                  <FormItem
                    label={t('JOB_PAYMENT_TERMS')}
                    name="payment_terms"
                    className="no-margin"
                  >
                    <SelectInput
                      size="large"
                      suffixIcon={
                        <CaretDownOutlined style={{ color: '#000' }} />
                      }
                    >
                      {PAYMENT_TERM_TYPES.map((payment_term, index) => (
                        <Select.Option value={payment_term.value} key={index}>
                          {payment_term.name}
                        </Select.Option>
                      ))}
                    </SelectInput>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label={t('JOB_PAYMENT_TERMS_START')}
                    name="payment_terms_start"
                    className="no-margin"
                  >
                    <SelectInput
                      size="large"
                      suffixIcon={
                        <CaretDownOutlined style={{ color: '#000' }} />
                      }
                    >
                      {PAYMENT_TERM_START_TYPES.map((payment_term, index) => (
                        <Select.Option value={payment_term.value} key={index}>
                          {payment_term.name}
                        </Select.Option>
                      ))}
                    </SelectInput>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: '1rem' }}>
                <Col span={8}>
                  <FormItem
                    label={t('JOB_RESTITUTION_PAYMENT_TERMS')}
                    name="restitution_payment_terms"
                    className="no-margin"
                  >
                    <SelectInput
                      size="large"
                      suffixIcon={
                        <CaretDownOutlined style={{ color: '#000' }} />
                      }
                    >
                      {PAYMENT_TERM_TYPES.map((payment_term, index) => (
                        <Select.Option value={payment_term.value} key={index}>
                          {payment_term.name}
                        </Select.Option>
                      ))}
                    </SelectInput>
                  </FormItem>
                </Col>
              </Row>
              <div
                style={{
                  width: '100%',
                  marginTop: '2.5rem',
                  borderBottom: '1px solid #DAE5EC',
                }}
              >
                <FormSubtitle
                  style={{ fontSize: '16px', paddingBottom: '1rem' }}
                >
                  {t('JOB_OTHER_INFORMATION')}
                </FormSubtitle>
              </div>
              <Row
                gutter={16}
                style={{ marginTop: '1rem', marginBottom: '2rem' }}
              >
                <Col span={24}>
                  <FormItem name="other_information" className="no-margin">
                    <Input maxLength={70} size="large" type="text" />
                  </FormItem>
                </Col>
              </Row>
            </>
          )}

        {showExtendedFeeSettings ? (
          <></>
        ) : (
          <FormItem>
            <Row gutter={16}>
              <Col span={8}>
                <FormItem
                  label="FEE STRUCTURE"
                  name="recruiter_compensation_type"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {FEE_TYPES.map((fee, index) => (
                      <Select.Option value={fee.fee_value} key={index}>
                        {fee.fee_name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="FEE DETAILS"
                  name="recruiter_compensation_type_tooltip"
                  className="no-margin"
                >
                  <Input
                    type="number"
                    placeholder={t('JOB_FEE_DETAILS_PLACEHOLDER')}
                    maxLength={70}
                    size="large"
                  />
                </FormItem>
              </Col>
            </Row>

            <Row
              gutter={16}
              style={{ marginTop: '1rem', marginBottom: '2rem' }}
            >
              <Col span={24}>
                <FormItem
                  label={t('JOB_OTHER_INFORMATION')}
                  name="other_information"
                  className="no-margin"
                >
                  <Input maxLength={70} size="large" type="text" />
                </FormItem>
              </Col>
            </Row>
          </FormItem>
        )}

        {company_wide_fee_settings_enabled && (
          <>
            <FormItem>
              <FormSubtitle>
                {t('JOB_EXTENDED_RECRUITER_AGREEMENT')}
              </FormSubtitle>
              <p>{t('JOB_EXTENDED_RECRUITER_AGREEMENT_TOOLTIP')}</p>
              {companyFeeTemplates.length > 0 && (
                <>
                  <Row
                    gutter={16}
                    style={{
                      marginTop: '1.5rem',
                    }}
                  >
                    <Col span={8}>
                      <FormItem label="FEE TEMPLATE">
                        <SelectInput
                          size="large"
                          suffixIcon={
                            <CaretDownOutlined style={{ color: '#000' }} />
                          }
                          onChange={onFeeTemplateChange}
                        >
                          {companyFeeTemplates.map((feeTemplate) => (
                            <Select.Option
                              value={feeTemplate.id}
                              key={feeTemplate.id}
                            >
                              {feeTemplate.name}
                            </Select.Option>
                          ))}
                        </SelectInput>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col
                      span={8}
                      style={{ alignSelf: 'end', marginBottom: '1.5rem' }}
                    >
                      <Button
                        type="ghost"
                        size="middle"
                        onClick={() =>
                          setShowExtendedFeeSettings(!showExtendedFeeSettings)
                        }
                      >
                        {t('RECRUITER_MANAGEMENT_SHOW_EXTENDED_FIELDS')}
                        {showExtendedFeeSettings ? (
                          <ArrowDownIcon
                            style={{ marginRight: '-1rem', rotate: '180deg' }}
                          />
                        ) : (
                          <ArrowDownIcon style={{ marginRight: '-1rem' }} />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              {showExtendedFeeSettings && (
                <>
                  <div
                    style={{
                      width: '100%',
                      marginTop: '1rem',
                      borderBottom: '1px solid #DAE5EC',
                    }}
                  >
                    <FormSubtitle
                      style={{ fontSize: '16px', paddingBottom: '1rem' }}
                    >
                      {t('FEE_SETUP_FEE_INFORMATION')}
                    </FormSubtitle>
                  </div>
                  <Row gutter={16} style={{ marginTop: '1rem' }}>
                    <Col span={8}>
                      <FormItem
                        label="FEE STRUCTURE"
                        name="recruiter_compensation_type"
                        className="no-margin"
                      >
                        <SelectInput
                          size="large"
                          suffixIcon={
                            <CaretDownOutlined style={{ color: '#000' }} />
                          }
                        >
                          {FEE_TYPES.map((fee, index) => (
                            <Select.Option value={fee.fee_value} key={index}>
                              {fee.fee_name}
                            </Select.Option>
                          ))}
                        </SelectInput>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label="FEE DETAILS"
                        name="recruiter_compensation_type_tooltip"
                        className="no-margin"
                      >
                        <Input
                          type="number"
                          placeholder={t('JOB_FEE_DETAILS_PLACEHOLDER')}
                          maxLength={70}
                          size="large"
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label={t('GUARANTEE_PERIOD')}
                        name="guarantee_period"
                        className="no-margin"
                      >
                        <Input
                          placeholder={t('GUARANTEE_PERIOD_PLACEHOLDER')}
                          maxLength={70}
                          size="large"
                          type="number"
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <div
                    style={{
                      width: '100%',
                      marginTop: '2.5rem',
                      borderBottom: '1px solid #DAE5EC',
                    }}
                  >
                    <FormSubtitle
                      style={{ fontSize: '16px', paddingBottom: '1rem' }}
                    >
                      {t('FEE_SETUP_RESTITUTION')}
                    </FormSubtitle>
                  </div>
                  <Row gutter={16} style={{ marginTop: '1rem' }}>
                    <Col span={8}>
                      <FormItem
                        label={t('JOB_RESTITUTION')}
                        name="restitution"
                        className="no-margin"
                      >
                        <Input
                          placeholder={t(
                            'COMPANY_AGREEMENT_RESTITUTION_PLACEHOLDER',
                          )}
                          maxLength={70}
                          size="large"
                          type="number"
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label={t('JOB_REPLACEMENT_TIME')}
                        name="replacement_time"
                        className="no-margin"
                      >
                        <Input
                          placeholder={t(
                            'COMPANY_AGREEMENT_REPLACEMENT_TIME_PLACEHOLDER',
                          )}
                          maxLength={70}
                          size="large"
                          type="number"
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label={t('JOB_EXCLUSIVITY_FOR_REPLACEMENT')}
                        name="exclusivity_for_replacement"
                        className="no-margin"
                      >
                        <SelectInput
                          placeholder={t(
                            'COMPANY_AGREEMENT_EXCLUSIVITY_FOR_REPLACEMENT_PLACEHOLDER',
                          )}
                          size="large"
                          allowClear
                          suffixIcon={
                            <CaretDownOutlined style={{ color: '#000' }} />
                          }
                        >
                          {['Yes', 'No'].map((val, index) => (
                            <Select.Option value={val} key={index}>
                              {val}
                            </Select.Option>
                          ))}
                        </SelectInput>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ marginTop: '1rem' }}>
                    <Col span={24}>
                      <FormItem
                        label={t('JOB_CUSTOM_RESTITUTION_AGREEMENT')}
                        name="custom_restitution_agreement"
                        className="no-margin"
                      >
                        <Input maxLength={70} size="large" type="text" />
                      </FormItem>
                    </Col>
                  </Row>
                  <div
                    style={{
                      width: '100%',
                      marginTop: '2.5rem',
                      borderBottom: '1px solid #DAE5EC',
                    }}
                  >
                    <FormSubtitle
                      style={{ fontSize: '16px', paddingBottom: '1rem' }}
                    >
                      {t('FEE_SETUP_PAYMENT_TERMS')}
                    </FormSubtitle>
                  </div>
                  <Row gutter={16} style={{ marginTop: '1rem' }}>
                    <Col span={8}>
                      <FormItem
                        label={t('JOB_PAYMENT_TERMS')}
                        name="payment_terms"
                        className="no-margin"
                      >
                        <SelectInput
                          size="large"
                          suffixIcon={
                            <CaretDownOutlined style={{ color: '#000' }} />
                          }
                        >
                          {PAYMENT_TERM_TYPES.map((payment_term, index) => (
                            <Select.Option
                              value={payment_term.value}
                              key={index}
                            >
                              {payment_term.name}
                            </Select.Option>
                          ))}
                        </SelectInput>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label={t('JOB_PAYMENT_TERMS_START')}
                        name="payment_terms_start"
                        className="no-margin"
                      >
                        <SelectInput
                          size="large"
                          suffixIcon={
                            <CaretDownOutlined style={{ color: '#000' }} />
                          }
                        >
                          {PAYMENT_TERM_START_TYPES.map(
                            (payment_term, index) => (
                              <Select.Option
                                value={payment_term.value}
                                key={index}
                              >
                                {payment_term.name}
                              </Select.Option>
                            ),
                          )}
                        </SelectInput>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ marginTop: '1rem' }}>
                    <Col span={8}>
                      <FormItem
                        label={t('JOB_RESTITUTION_PAYMENT_TERMS')}
                        name="restitution_payment_terms"
                        className="no-margin"
                      >
                        <SelectInput
                          size="large"
                          suffixIcon={
                            <CaretDownOutlined style={{ color: '#000' }} />
                          }
                        >
                          {PAYMENT_TERM_TYPES.map((payment_term, index) => (
                            <Select.Option
                              value={payment_term.value}
                              key={index}
                            >
                              {payment_term.name}
                            </Select.Option>
                          ))}
                        </SelectInput>
                      </FormItem>
                    </Col>
                  </Row>
                  <div
                    style={{
                      width: '100%',
                      marginTop: '2.5rem',
                      borderBottom: '1px solid #DAE5EC',
                    }}
                  >
                    <FormSubtitle
                      style={{ fontSize: '16px', paddingBottom: '1rem' }}
                    >
                      {t('JOB_OTHER_INFORMATION')}
                    </FormSubtitle>
                  </div>
                  <Row gutter={16} style={{ marginTop: '1rem' }}>
                    <Col span={24}>
                      <FormItem name="other_information" className="no-margin">
                        <Input maxLength={70} size="large" type="text" />
                      </FormItem>
                    </Col>
                  </Row>
                  <div
                    style={{
                      width: '100%',
                      marginTop: '2.5rem',
                      borderBottom: '1px solid #DAE5EC',
                    }}
                  >
                    <FormSubtitle
                      style={{ fontSize: '16px', paddingBottom: '1rem' }}
                    >
                      {t('RECRUITER_CONTRACT')}
                    </FormSubtitle>
                  </div>
                  <Row gutter={16} style={{ marginTop: '1rem' }}>
                    <>
                      {file && !newFileUploading ? (
                        <div style={{ display: 'flex' }}>
                          <ActionWrapper>
                            <ActionLink
                              href={file as string}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <DownloadIcon />
                              {t('COMPANY_AGREEMENT_DOWNLOAD_CONTRACT')}
                            </ActionLink>
                          </ActionWrapper>
                          <UploadAction>
                            <button
                              style={{
                                border: 'none',
                                display: 'flex',
                                backgroundColor: 'transparent',
                              }}
                            >
                              <DeleteFileIcon onClick={handleFileDelete} />
                            </button>
                          </UploadAction>
                        </div>
                      ) : (
                        <Col span={8}>
                          <FormItem
                            label={t('COMPANY_AGREEMENT_CONTRACT')}
                            name="contract"
                          >
                            <StyledUploader
                              name="file"
                              showUploadList={false}
                              beforeUpload={handleUploadFile}
                              customRequest={() => void 0}
                            >
                              <FileUploader>
                                <FileName>{file}</FileName>
                                <UploadAction>
                                  <Button type={'default'} size="small">
                                    {t('CHOOSE_FILE')}
                                  </Button>

                                  {file && (
                                    <DeleteFileIcon
                                      onClick={handleFileDelete}
                                    />
                                  )}
                                </UploadAction>
                              </FileUploader>
                            </StyledUploader>
                          </FormItem>
                        </Col>
                      )}
                    </>
                  </Row>
                </>
              )}
            </FormItem>
          </>
        )}
      </div>
      <FormActions>
        <Button
          type="ghost"
          loading={loading}
          onClick={() => handleClick(false)}
        >
          {t('SAVE_AND_PREVIEW_DRAFT_TITLE')}
        </Button>
        <Button type="primary" onClick={(e) => handleClick(true)}>
          {t('NEXT_TITLE')}
        </Button>
      </FormActions>
    </Form>
  );
}
